import React from "react";
import AdvisorsSaying from "../components/Advisors/AdvisorsSaying";
import AwardsSection from "../components/Advisors/AwardsSection";
import BannerSection from "../components/Advisors/BannerSection";
import BenefitsSection from "../components/Advisors/BenefitsSection";
import CounterSection from "../components/Advisors/CounterSection";
import DonateTableSection from "../components/Advisors/DonateTableSection";
import FormSection from "../components/Advisors/FormSection";
import HowItWorksSection from "../components/Advisors/HowItWorksSection";
import SupportSection from "../components/Advisors/SupportSection";
import VideoSection from "../components/Advisors/VideoSection";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/solutions",
  path: "/advisors",
  title: "Donor-Advised Funds for Advisors in Canada",
  description:
    "Leverage Canada's leading online donor-advised fund to serve your clients’ goals and their vision of a brighter future.",
};

const advisors = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <VideoSection />
      <BenefitsSection />
      <DonateTableSection />
      <HowItWorksSection />
      <SupportSection />
      <CounterSection />
      <AwardsSection />
      <AdvisorsSaying />
      <FormSection />
    </Layout>
  );
};

export default advisors;
