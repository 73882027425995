import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Container, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledPara } from '../../styles/globalStyles';

const SectionWrap = styled.section`
  color: #263238;
`;

const TabsWrap = styled.div`
  background: #fff;
  padding: 40px;
  margin-top: -120px;
  border: 1px solid #f1f6fd;
  border-radius: 6px;

  @media only screen and (max-width: 768px) {
    padding: 40px 16px 24px 16px;
    flex-direction: column;
  }

  .nav-item {
    margin-bottom: 20px;

    .nav-link {
      color: #7f7f7f;
      font-size: 20px;
      font-weight: 600;
      background: #fff;
      position: relative;
      font-family: 'ProximaNova-Semibold';

      &::before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        border-radius: 4px;
      }

      &:hover,
      &.active {
        color: #90459a;
        background: #fbfbfb;
        box-shadow: 0px 2px 7px rgba(135, 135, 135, 0.3);
        border-radius: 3px;

        &::before {
          background: #90459a;
        }
      }
    }
  }
`;

const TabGroup = styled.div`
  margin-bottom: 30px;

  h4 {
    font-size: 17px;
  }

  ul {
    list-style: none;
    padding-left: 20px;
    padding-top: 10px;

    li {
      margin-bottom: 5px;
      padding-left: 5px;
      position: relative;
      font-size: 16px;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        background: #000;
        left: -10px;
        top: 10px;
        border-radius: 50%;
      }
    }
  }
`;

const TabButton = styled.div`
  padding-top: 20px;

  @media only screen and (max-width: 767px) {
    text-align: center;
  }

  a {
    color: #055ce5;
    border: 1px solid #055ce5;
    border-radius: 40px;
    display: inline-block;
    padding: 10px 35px;
    font-family: 'ProximaNova-Semibold';
    transition: 0.3s ease;

    &:hover {
      color: #fff;
      background-color: #055ce5;
    }
  }
`;

const NavTabs = styled.div`
  flex: 0 0 auto;
  max-width: 250px;

  @media only screen and (max-width: 768px) {
    flex-shrink: 0;
    width: 100%;
  }
`;

const NavContent = styled.div`
  padding: 10px 0 0;

  @media only screen and (min-width: 820px) {
    padding: 0 40px;
  }
`;

const NavItem = styled.div`
  margin-bottom: 20px;
`;

const NavLink = styled.a`
  color: #7f7f7f;
  font-size: 20px;
  font-weight: 600;
  background: #fff;
  position: relative;
  font-family: 'ProximaNova-Semibold';
  padding: 8px 10px;
  cursor: pointer;
  display: block;

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border-radius: 4px;
  }

  &:hover,
  &.active {
    color: #90459a;
    background: #fbfbfb;
    box-shadow: 0px 2px 7px rgb(135 135 135 / 30%);
    border-radius: 3px;

    &::before {
      background: #90459a;
    }
  }
`;

const TabContent = styled.div``;

const TabPane = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;

const BenefitsSection = () => {
  const [hovered, setHovered] = useState(1);

  const handleTabHovered = (index) => {
    setHovered(index);
  };

  return (
    <SectionWrap>
      <Container>
        <TabsWrap className='d-flex'>
          <Tab.Container
            id='benefit-left-tabs'
            defaultActiveKey='benefits-for-you'>
            <NavTabs>
              <NavItem>
                <NavLink
                  id='benefits-for-you'
                  className={hovered === 1 ? 'active' : ''}
                  onMouseEnter={() => handleTabHovered(1)}>
                  Benefits for you
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  id='benefits-for-clients'
                  className={hovered === 2 ? 'active' : ''}
                  onMouseEnter={() => handleTabHovered(2)}>
                  Benefits for your clients
                </NavLink>
              </NavItem>
            </NavTabs>
            <NavContent>
              <TabContent>
                <TabPane
                  id='tab-pane-benefits-for-you'
                  className={hovered === 1 ? 'active' : ''}>
                  <TabGroup>
                    <h4>Retain and grow assets under management</h4>
                    <StyledPara>
                      Continue to earn and charge fees when clients donate and
                      invest publicly traded securities.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>Strengthen client relationships</h4>
                    <StyledPara>
                      Remain your clients’ advisor of choice when it comes to
                      charitable giving. We’re here to support discussions about
                      donation and gift planning.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>
                      Offer charitable giving to clients, without being an
                      expert
                    </h4>
                    <StyledPara>
                      Make charitable giving easier and more convenient. We take
                      away the administrative burden from donating and giving
                      assets and make managing charitable investments
                      straightforward.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>
                      Use one <a href='/fees'>low cost</a>, high value solution
                      to help clients give
                    </h4>
                    <StyledPara>
                      Process and manage donations for all of your clients with
                      Charitable Impact, no matter which causes or charities
                      they support.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>Plan the use of clients’ money in a meaningful way</h4>
                    <StyledPara>
                      Your clients trust you to build the best financial plan
                      for their unique goals. Help your clients increase their
                      tax savings, grow their charitable assets over time, and
                      make a greater impact on the causes that matter to them.
                    </StyledPara>
                  </TabGroup>
                  <TabButton>
                    <Link to='#get-more-info'>Get more information</Link>
                  </TabButton>
                </TabPane>

                <TabPane
                  id='tab-pane-benefits-for-clients'
                  className={hovered === 2 ? 'active' : ''}>
                  <TabGroup>
                    <h4>Work with a trusted advisor</h4>
                    <StyledPara>
                      Clients can plan their charitable giving with their
                      existing wealth professional. We’re here to support you
                      both.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>Take time and space to give intentionally</h4>
                    <StyledPara>
                      Donate and receive an immediate tax receipt for the value
                      of the donation, then decide separately how to use that
                      charitable donation.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>Maximize tax savings</h4>
                    <StyledPara>
                      Eliminate capital gains tax and be left with a larger
                      charitable tax credit by donating publicly traded
                      securities. You can donate other non-cash assets too, such
                      as private company shares, life insurance, and real
                      estate.
                    </StyledPara>
                  </TabGroup>
                  <TabGroup>
                    <h4>Manage charitable giving from one online platform</h4>
                    <ul>
                      <li>
                        Give simply on the web or mobile app to any Canadian
                        registered charity
                      </li>
                      <li>
                        Trackable giving history and downloadable tax receipts
                      </li>
                      <li>
                        Automated giving options and the ability to give with
                        family and friends
                      </li>
                      <li>
                        Dedicated Donor Experience team available for support
                        with giving from the Impact Account
                      </li>
                    </ul>
                  </TabGroup>
                  <TabGroup>
                    <h4>Get advice to navigate your giving options</h4>
                    <StyledPara>
                      It can be difficult to know exactly how to make an impact.
                      Access one-on-one impartial support from Philanthropic
                      Advisory Services to consider, plan, and carry out
                      charitable gifts.
                    </StyledPara>
                  </TabGroup>
                  <TabButton>
                    <Link to='#get-more-info'>Get more information</Link>
                  </TabButton>
                </TabPane>
              </TabContent>
            </NavContent>
          </Tab.Container>
        </TabsWrap>
      </Container>
    </SectionWrap>
  );
};

export default BenefitsSection;
