import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledPara } from "../../styles/globalStyles";
import { StyledH1 } from "./DonateTableSection";

const SectionWrap = styled.section`
  padding: 60px 0;
`;

const InfoWrapper = styled.div`
  background-color: #f5f8fc;
  border-radius: 5px;
  padding: 70px 20px 50px;
`;

const FormHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h1 {
    color: #263238;
  }
`;

const InfoFormWrapper = styled.div`
  @media only screen and (max-width: 767px) {
    margin-top: 20px;
  }

  label {
    margin-bottom: 0.5rem;
    color: #263238;
    font-weight: 600;

    .hs-form-required {
      display: none;
    }
  }

  .hs-form-field {
    margin-bottom: 16px;
  }

  .hs-main-font-element {
    display: none;
  }

  .input {
    .hs-input {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      display: block;
      height: 41px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;

      &.invalid {
        &.error {
          border: 1px solid red;
        }
      }
    }
  }

  ul {
    &.hs-error-msgs {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .hs-error-msg {
    color: red;
    font-family: ProximaNova-Regular;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 0;
    margin-left: 0;
  }

  .hs-submit {
    text-align: center;
  }

  .hs-button.primary {
    background-color: #055ce5;
    border: none;
    border-radius: 20.5px;
    box-shadow: none;
    color: #fff;
    font-size: 16px;
    height: 41px;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
`;

const InfoMailAddress = styled.div`
  color: #424242;
  font-family: ProximaNova-Medium;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
`;

const HubSpotForm = styled.div``;

class FormSection extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (typeof window !== "undefined") {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "2004091",
            formId: "6935ab13-c341-4653-a3e3-d10764da773e",
            target: "#hubspot-form",
          });
        }
      }
    });
  }

  render() {
    return (
      <SectionWrap id="get-more-info">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={10}>
              <InfoWrapper>
                <Row className="justify-content-md-center">
                  <FormHeader>
                    <StyledH1>Get more information</StyledH1>
                    <StyledPara>
                      Learn more about how Charitable Impact can help you grow
                      your business.
                    </StyledPara>
                  </FormHeader>

                  <Col md={7} lg={5}>
                    <InfoFormWrapper>
                      {/* Form Space */}
                      <HubSpotForm id="hubspot-form"></HubSpotForm>
                      {/* Form Space End */}

                      <InfoMailAddress className="mt-3">
                        Or, email{" "}
                        <a href="mailto:advisors@charitableimpact.com">
                          advisors@charitableimpact.com
                        </a>
                      </InfoMailAddress>
                    </InfoFormWrapper>
                  </Col>
                </Row>
              </InfoWrapper>
            </Col>
          </Row>
        </Container>
      </SectionWrap>
    );
  }
}

export default FormSection;
