import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 80px 0 50px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 991px) {
    padding: 40px 0 40px;
  }

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/awards/curve-line.png");
    width: 92px;
    height: 105px;
    bottom: -28px;
    left: -8px;

    @media only screen and (min-width: 1200px) {
      width: 190px;
      height: 218px;
      bottom: -40px;
      left: -50px;
    }

    @media only screen and (min-width: 1400px) {
      width: 218px;
      height: 250px;
      bottom: -70px;
      left: 0px;
    }
  }
`;

const BannerContent = styled.div`
  position: relative;

  @media only screen and (max-width: 991px) {
    padding-left: 45px;
  }

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/awards/shape-5.png");
    width: 30px;
    height: 28px;
    left: 0;
    top: 18px;

    @media only screen and (min-width: 768px) {
      left: -15px;
    }

    @media only screen and (min-width: 991px) {
      left: -40px;
    }

    @media only screen and (min-width: 1200px) {
      left: -56px;
    }

    @media only screen and (min-width: 1400px) {
      width: 40px;
      height: 37px;
      left: -80px;
      top: 10px;
    }
  }
`;

const BannerImage = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledH1 = styled.h1`
  color: #263238;
  font-size: 32px;
  font-weight: 600;
  line-height: 51px;
  position: relative;
  font-family: "ProximaNova-Regular";

  @media only screen and (max-width: 991px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

const StyledH3 = styled.h3`
  color: #90459a;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  line-height: 29px;
  font-family: "ProximaNova-Regular";

  @media only screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const BannerSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={7} lg={6}>
            <BannerContent className="mt-md-4">
              <StyledH1>Leverage Canada’s leading donor-advised fund</StyledH1>
              <StyledH3>Charitable Impact for advisors</StyledH3>
            </BannerContent>
          </Col>
          <Col md={5} lg={6}>
            <BannerImage className="text-center">
              <img
                src="/advisors/advisor-banner-image.png"
                width={383}
                alt=""
              />
            </BannerImage>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default BannerSection;
