import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { StyledPara } from "../../styles/globalStyles";
import { StyledH1 } from "./DonateTableSection";

const SectionWrap = styled.section`
  color: #263238;
`;

const SliderWrap = styled.div`
  .swiper-wrapper {
    .swiper-slide {
      position: relative;
      width: 460px;
      padding: 30px 20px 30px 40px;

      @media only screen and (max-width: 767px) {
        width: 290px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 20px;
        left: 20px;
        background: url("/advisors/quotes-icon.png") no-repeat;
        width: 19px;
        height: 15px;
        background-size: contain;

        @media only screen and (max-width: 768px) {
          left: 10px;
        }
      }

      &:last-child {
        &::before {
          content: "";
          width: 2px;
          background: #dde8fa;
          position: absolute;
          top: 25px;
          bottom: 40px;
          left: 0px;
          z-index: 1;

          @media only screen and (max-width: 768px) {
            top: 40px;
            bottom: 40px;
          }
        }
      }
    }
  }

  .card {
    border: 0;
    height: 100%;

    .card-body {
      padding: 0 20px;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }

      p {
        margin-bottom: 35px;

        @media only screen and (max-width: 767px) {
          margin-bottom: 25px;
        }
      }

      h6 {
        font-size: 14px;
        line-height: 19px;
        font-family: "ProximaNova-Semibold";
      }
    }
  }
`;

const AdvisorsSaying = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <StyledH1>What advisors are saying</StyledH1>
          </Col>

          <Col md={12}>
            <SliderWrap>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                breakpoints={{
                  640: {
                    spaceBetween: 20,
                  },
                  768: {
                    spaceBetween: 0,
                    slidesPerView: 2,
                  },
                  1024: {
                    spaceBetween: 0,
                    slidesPerView: 2,
                  },
                }}
                className="how-it-works-steps"
              >
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <StyledPara>
                        Charitable Impact has been a tremendous platform and
                        resource for my clients. It provides them with an
                        effective and straightforward way to fulfill their
                        philanthropic goals and integrates seamlessly into their
                        wealth management plan.
                      </StyledPara>
                      <h6>Dave Lougheed</h6>
                      <h6 className="m-0">
                        Portfolio Manager & Wealth Advisor, Lougheed Wealth
                        Management of Raymond James Ltd.
                      </h6>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <StyledPara>
                        The team at Charitable Impact has been great to partner
                        with, allowing our clients to accomplish their goals of
                        tax planning combined with charitable gifting. The
                        account setup and administration process are seamless.
                      </StyledPara>
                      <h6>Brendon Boothman</h6>
                      <h6 className="m-0">
                        Portfolio Manager, Boothman Private Wealth Management of
                        RBC Dominion Securities Inc.
                      </h6>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </Swiper>

              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                breakpoints={{
                  640: {
                    spaceBetween: 20,
                  },
                  768: {
                    spaceBetween: 0,
                    slidesPerView: 2,
                  },
                  1024: {
                    spaceBetween: 0,
                    slidesPerView: 2,
                  },
                }}
                className="how-it-works-steps"
              >
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <StyledPara>
                        Charitable Impact has quickly become our firm’s reliable
                        go-to solution for our clients’ philanthropic
                        endeavours. They have continuously provided our clients
                        with an impressive experience from start to finish. The
                        team is very knowledgeable and highly responsive.
                      </StyledPara>
                      <h6>Matthew Gour</h6>
                      <h6 className="m-0">
                        Associate Portfolio Manager, PWL Capital Inc.
                      </h6>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <StyledPara>
                        At Wellington-Altus, we know that philanthropy is an
                        integral part of wealth management. Charitable Impact is
                        one of the very few donor-advised funds that our clients
                        can use to direct their investments while we can stay
                        involved. My clients and I appreciate the convenience,
                        flexibility, and friendly personal service that makes it
                        easy for us to work together.
                      </StyledPara>
                      <h6>Gord Love</h6>
                      <h6 className="m-0">
                        Executive Vice-President and Senior Portfolio Manager,
                        Wellington-Altus Private Wealth Inc.
                      </h6>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </SliderWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default AdvisorsSaying;
