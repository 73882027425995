import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CounterComponent, {
  CounterItem,
  CounterNumber,
} from "../CounterComponent";
import { StyledH1 } from "./DonateTableSection";

const SectionWrap = styled.section`
  padding: 40px 0;
  background: #fbfbfb;
  position: relative;
  z-index: 1;

  ${CounterItem} {
    padding: 25px 42px;

    @media (max-width: 991px) {
      padding: 25px 15px;
    }

    @media (max-width: 767px) {
      padding: 15px 42px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &::before {
      background-color: #dde8fa;

      @media (max-width: 575px) {
        height: 3px;
        right: 40%;
        left: 40%;
      }
    }

    p {
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }

  ${CounterNumber} {
    display: inline-block;

    &::before {
      content: "";
      background: url("/advisors/start-icon.png") no-repeat;
      width: 24px;
      height: 20px;
      background-size: contain;
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &::after {
    content: "";
    position: absolute;
    background: url("/advisors/chimp-bank.png") no-repeat;
    width: 260px;
    height: 282px;
    background-size: contain;
    right: 10px;
    top: -26px;
    z-index: -1;

    @media only screen and (max-width: 1400px) {
      width: 200px;
      height: 230px;
    }

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`;

const CounterWrap = styled.div`
  h1 {
    font-size: 32px;
  }

  p {
    line-height: 21px;
  }
`;

const CounterSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <StyledH1 className="text-center">Trusted across Canada</StyledH1>
            <CounterWrap>
              <CounterComponent />
            </CounterWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CounterSection;
