import { Link } from "gatsby";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH1 } from "./DonateTableSection";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { StyledPara } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  background: #f5f8fc;
  padding: 40px 0;
  color: #263238;

  a {
    font-family: "ProximaNova-Semibold";
  }

  h4 {
    font-size: 17px;
  }
`;

const SliderSection = styled.div`
  margin-top: 20px;

  .swiper-wrapper {
    justify-content: space-between;

    .swiper-slide {
      width: 300px;
      height: auto;

      @media only screen and (max-width: 767px) {
        width: 260px;
      }
    }
  }

  .card {
    border: 0;
    height: 100%;

    .card-body {
      padding: 24px;
    }
  }
`;

const HowItWorksSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={12}>
            <StyledH1>How our donor-advised fund works</StyledH1>
            <h4>
              We make donating, investing, and giving assets straightforward and
              effective.
            </h4>

            <StyledPara className="mb-2">
              Once a donation is made, a tax receipt for the fair market value
              of the assets is issued and the assets become charitable. They’re
              held in your client’s donor-advised fund at CHIMP: Charitable
              Impact Foundation (Canada), a registered charity and public
              foundation.
            </StyledPara>

            <StyledPara className="mb-2">
              Your client can recommend to invest and grow their charitable assets in the Charitable Investment Account, or liquidate them to the Impact Account and give to charities immediately.
            </StyledPara>
            <StyledPara>
              Learn how the Charitable Investment Account and Impact Account work together in our <Link to="/advisor-guide/charitable-impacts-donor-advised-fund" target={`_blank`}>Guide for Advisors</Link>.
            </StyledPara>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={11}>
            <SliderSection>
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                breakpoints={{
                  640: {
                    spaceBetween: 20,
                  },
                  768: {
                    spaceBetween: 40,
                  },
                  1024: {
                    spaceBetween: 40,
                  },

                  1200: {
                    spaceBetween: 0,
                  },
                }}
                className="how-it-works-steps"
              >
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <h4>Donate & Invest</h4>
                      <StyledPara className="mb-2">
                        Invested charitable assets grow tax-free and you can
                        continue to manage the donated assets according to our
                        investment policy guidelines. The invested assets stay
                        at your firm and on your book, where you can earn and
                        charge fees.
                      </StyledPara>
                      <StyledPara>
                        Learn more about{" "}
                        <a href="/advisor-guide/charitable-impacts-donor-advised-fund/#making-a-donation" target="_blank">
                          Donate & Invest
                        </a>
                      </StyledPara>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <h4>Donate & Give</h4>
                      <StyledPara className="mb-2">
                        Some clients want to give away donated assets
                        immediately and may not be looking for ongoing
                        investment management. You can liquidate their donated
                        publicly traded securities and make the cash proceeds
                        available in your client’s Impact Account so they can
                        use the charitable dollars to make a grant.
                      </StyledPara>
                      <StyledPara>
                        Learn more about{" "}
                        <a href="/advisor-guide/charitable-impacts-donor-advised-fund/#making-a-donation" target="_blank">
                          Donate & Give
                        </a>
                      </StyledPara>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <Card.Body>
                      <h4>Or, choose both</h4>
                      <StyledPara className="mb-2">
                        Often, clients will prefer to support charities
                        immediately with a portion of their donation, while
                        retaining the remaining balance with you to manage over
                        time. With Charitable Impact, it’s simple to do both.
                      </StyledPara>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </SliderSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default HowItWorksSection;
