import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledPara } from "../../styles/globalStyles";
import { StyledH1, StyledH4 } from "./DonateTableSection";

const SectionWrap = styled.section`
  padding: 40px 0;
  color: #263238;

  a {
    font-family: "ProximaNova-Semibold";
  }
`;

const ImageSection = styled.div`
  ul {
    list-style: none;

    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 1rem;
      font-size: 16px;
      line-height: 21px;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #263238;
      }
    }
  }

  img {
    @media only screen and (max-width: 991px) {
      width: 80%;
      display: block;
      margin: 0 auto 30px;
    }

    @media only screen and (max-width: 767px) {
      margin: 0 auto;
    }
  }
`;

const Content = styled.div`
  p {
    padding-left: 33px;

    @media only screen and (max-width: 767px) {
      padding-left: 0;
    }
  }
`;

const SupportSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-between align-items-center">
          <Col lg={5}>
            <ImageSection>
              <StyledH1>We’re here to support you</StyledH1>
              <ul>
                <li>
                  Our team can help with each donation and during the ongoing investment of donated assets.
                </li>
                <li>
                  Philanthropic Advisory Services can help your clients navigate the charitable sector.
                </li>
                <li>
                  Use the <Link to='/advisor-guide'>Guide for Advisors</Link> to review the process for facilitating donations of publicly traded securities.
                </li>
                <li>
                  <Link to="/advisor-guide/get-support"> Connect with us by phone or email</Link> any time you want.
                </li>
              </ul>

              <img src="/advisors/support-image.png" alt="" />
            </ImageSection>
          </Col>
          <Col lg={6}>
            <Content>
              <StyledH4 className="d-flex align-items-baseline">
                <img
                  src="/advisors/shape-3.png"
                  alt="icon"
                  width={23}
                  style={{ marginRight: "10px" }}
                />{" "}
                <span>Philanthropic Advisory Services</span>
              </StyledH4>

              <StyledPara>
                Wanting to help others comes naturally, but knowing how to make a difference through charitable giving doesn’t. It can be difficult for donors to know how to make an impact when there are countless causes and <a href="https://my.charitableimpact.com/search?result_type=Beneficiary" target='_blank'>over 86,000 Canadian registered charities</a>. There’s generally nowhere to get objective help with charitable giving.
              </StyledPara>

              <StyledPara>
                That’s where our Philanthropic Advisory Services can help, so you don’t have to be a charity expert. Your clients receive personalized, cause-neutral giving support. Plus, we help your clients consider their giving options, then plan and carry out charitable gifts.
              </StyledPara>

              <StyledPara>
                Charitable giving is what we do. We’re here to help your clients navigate the charitable world and give meaningfully.
              </StyledPara>
            </Content>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default SupportSection;