import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { StyledPara } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  padding: 60px 0;
  color: #263238;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  &::before {
    background-image: url("/awards/shape-6.png");
    width: 45px;
    height: 41px;
    top: 105px;
    right: 236px;
  }

  &::after {
    background-image: url("/advisors/shape-2.png");
    width: 155px;
    height: 120px;
    top: 40px;
    right: 0;
  }
`;

const Content = styled.div``;

export const StyledH1 = styled.h1`
  font-size: 32px;
  color: #90459a;
  font-family: "ProximaNova-Semibold";
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const StyledH4 = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  font-family: "ProximaNova-Semibold";
`;

const StyledH5 = styled.h5`
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
  font-family: "ProximaNova-Semibold";
`;

const TableWrap = styled.div`
  margin-top: 15px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    background: url("/advisors/shape-dot-rectangle.png") no-repeat;
    width: 280px;
    height: 200px;
    background-size: contain;
    top: -40px;
    right: -35px;
    z-index: -1;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  ${StyledPara} {
    color: #7f7f7f;
    margin-top: 10px;
    max-width: 600px;
  }
`;

const TableContainer = styled.div`
  background: #fbfbfb;
  border-radius: 6px;
  padding: 8px 16px 16px;

  @media only screen and (max-width: 767px) {
    padding: 8px 8px 16px;
  }

  table {
    thead {
      tr {
        text-align: center;

        th {
          border: 0;
          font-size: 17px;
          padding: 15px 15px 20px;
          font-family: "ProximaNova-Semibold";

          @media only screen and (max-width: 768px) {
            padding: 8px 8px 16px 8px;
            font-size: 12px;
            line-height: 15px;
          }

          @media only screen and (max-width: 767px) {
            padding: 0 8px 8px;
          }
        }
      }
    }

    tbody {
      tr {
        text-align: center;
        vertical-align: middle;

        td {
          border: 0;
          background: #fff;
          margin-bottom: 10px;
          padding: 8px;
          line-height: 19px;

          @media only screen and (max-width: 768px) {
            padding: 10px 32px;
          }

          @media only screen and (max-width: 767px) {
            padding: 4px 8px;
            height: 46px;
          }

          &:nth-child(1) {
            text-align: left;
          }
        }
      }

      th {
        padding: 0;
        border: 0;

        hr {
          border-top-width: 2px;
          border-color: #dde8fa;
        }
      }
    }
  }
`;

const StyledUL = styled.ul`
  list-style: none;

  @media only screen and (max-width: 991px) {
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 15px;
    margin-top: 15px;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    span {
      display: inline-flex;
      align-items: center;
      font-size: 17px;
      font-weight: 600;
      padding: 8px 10px 8px 18px;
      background: #fbfbfb;
      border-radius: 5px;
      position: relative;

      &::before {
        content: "";
        width: 5px;
        position: absolute;
        border-radius: 1px;
        top: 8px;
        left: 8px;
        bottom: 8px;
        background: #90459a1a;
      }

      img {
        height: 40px;
        object-fit: contain;
      }

      label {
        margin-left: 10px;
      }
    }
  }
`;

const DonateTableSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={7}>
            <Content>
              <StyledH1>What your clients can donate</StyledH1>
              <StyledPara>
                Donating non-cash assets can be more tax effective than donating
                cash. Charitable Impact offers many ways for your clients to
                donate non-cash assets.
              </StyledPara>
              <StyledPara>
                When your client donates appreciated{" "}
                <strong style={{ fontFamily: "Proximanova-Medium" }}>
                  publicly traded securities
                </strong>
                , they benefit from a capital gains exemption, leaving them with
                a larger charitable tax credit to offset their income.
              </StyledPara>

              <StyledH4 className="d-flex align-items-baseline">
                <img
                  src="/advisors/shape-1.png"
                  alt="icon"
                  width={23}
                  style={{ marginRight: "10px" }}
                />{" "}
                <span>Tax savings of donating securities in-kind</span>
              </StyledH4>
              <StyledPara>
                Reduce capital gains tax to $0 and offset more taxable income.
              </StyledPara>
            </Content>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={10}>
            <TableWrap>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Sell securities and donate cash</th>
                      <th>Donate securities in-kind</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Value of securities at time of donation</td>
                      <td>$10,000</td>
                      <td>$10,000</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>Original cost of securities</td>
                      <td>$1,000</td>
                      <td>$1,000</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>Capital gain on securities</td>
                      <td>$9,000</td>
                      <td>$9,000</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>Taxable capital gain</td>
                      <td>$4,500</td>
                      <td>$0</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>Tax owed on capital gain</td>
                      <td>$1,980</td>
                      <td>$0</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>Tax credit</td>
                      <td>$4,000</td>
                      <td>$4,000</td>
                    </tr>
                    <tr>
                      <th colSpan={3}>
                        <hr />
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tax savings</strong>
                      </td>
                      <td>
                        <strong>$2,020</strong>
                      </td>
                      <td>
                        <strong>$4,000</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </TableContainer>
              <StyledPara>
                In this example, tax owed on capital gain assumes a blended tax
                rate of 44%. The tax credit assumes a blended charitable
                donation tax credit rate of 40%.
              </StyledPara>
            </TableWrap>
          </Col>
        </Row>

        <Row>
          <Col lg={7}>
            <StyledH5>Key takeaway:</StyledH5>
            <StyledPara>
              The charity receives a $10,000 donation in both cases. By donating
              publicly-traded securities in-kind instead of selling them first,
              the donor receives increased tax savings.
            </StyledPara>
          </Col>

          <Col lg={12}>
            <StyledH4 className="d-flex align-items-baseline">
              <img
                src="/advisors/shape-1.png"
                alt="icon"
                width={23}
                style={{ marginRight: "10px" }}
              />{" "}
              <span>
                In addition to publicly traded securities, we also accept
              </span>
            </StyledH4>
            <StyledUL>
              <li>
                <span>
                  <img
                    src="/advisors/life-insurance.png"
                    alt="Life insurance"
                  />
                  <label>Life insurance</label>
                </span>
              </li>
              <li>
                <span>
                  <img
                    src="/advisors/private-company-shares.png"
                    alt="Private company shares"
                  />
                  <label>Private company shares</label>
                </span>
              </li>
              <li>
                <span>
                  <img src="/advisors/real-estate.png" alt="Real estate" />
                  <label>Real estate</label>
                </span>
              </li>
              <li>
                <span>
                  <img
                    src="/advisors/cryptocurrency.png"
                    alt="Cryptocurrency"
                  />
                  <label>Cryptocurrency</label>
                </span>
              </li>
              <li>
                <span>
                  <img src="/advisors/art.png" alt="Art" />
                  <label>Art</label>
                </span>
              </li>
            </StyledUL>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default DonateTableSection;
