import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledH1 } from "./DonateTableSection";

const SectionWrap = styled.section`
  font-family: ProximaNova-Regular;
  padding: 65px 0;
  color: #424242;

  @media only screen and (max-width: 768px) {
    padding: 45px 0;
  }
`;

const StyledPara = styled.p`
  font-size: 20px;
  max-width: 580px;
  margin: auto;
  line-height: 24px;
`;

const AwardMedalImage = styled.div``;

const AwardsForAdvisors = styled.div`
  padding-top: 65px;

  h3 {
    font-size: 24px;
  }
`;

const AwradsWinners = styled.div`
  padding: 0 10px;
`;

const AwradsWinnersImage = styled.div`
  margin-bottom: 24px;
`;

const AwradsWinnersContent = styled.div`
  p {
    color: #263238;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  a {
    font-size: 16px;
    color: #055ce5;
    font-family: "ProximaNova-Semibold";

    @media only screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

const AwardsSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <StyledH1>Awards</StyledH1>

            <AwardMedalImage className="mt-5 mb-2">
              <a
                href="https://premium.wealthprofessional.ca/ca-5-star-wealth-technology-providers-charitable-impact/p/1"
                target="_blank"
              >
                <img
                  src="/financial-advisor/WPC-5-Star-WealthTech-Providers-medals_Winner.png"
                  width="180"
                  alt=""
                />
              </a>
            </AwardMedalImage>

            <StyledPara>
              We’re recognized for our supportive approach to working with
              financial advisors.
            </StyledPara>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={9} lg={8}>
            <AwardsForAdvisors className="text-center">
              <AwradsWinners>
                <AwradsWinnersImage className="text-center">
                  <img src="/CI-awards.png" width={350} alt="" />
                </AwradsWinnersImage>
                <AwradsWinnersContent>
                  <p>
                    The Awards recognize Charitable Impact’s wealth advisor
                    teams for their outstanding efforts in engaging their
                    clients with charitable giving.
                  </p>
                  <a href="/awards" target="_blank">
                    See the award winners
                  </a>
                </AwradsWinnersContent>
              </AwradsWinners>
            </AwardsForAdvisors>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default AwardsSection;
