import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledPara } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  padding: 60px 0 180px;
  background: #f5f8fc;

  @media only screen and (max-width: 767px) {
    padding: 40px 0 180px;
  }
`;

const ContentSection = styled.div``;

const StyledH3 = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: #263238;
  margin-bottom: 24px;
  font-weight: 600;
`;

const VideoWrap = styled.div`
  border-radius: 20px;
  overflow: hidden;
  height: 304px;

  @media only screen and (max-width: 991px) {
    height: 300px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 767px) {
    height: 194px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const VideoSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col md={12} lg={6}>
            <ContentSection>
              <StyledH3>
                Grow your business by helping your clients give.
              </StyledH3>

              <StyledPara>
                A donor-advised fund is like a bank account for charitable giving. It’s a tool that provides donors a chance to donate first, then decide separately how they want to use that charitable donation. Donated assets can be held in and invested by a donor-advised fund.
              </StyledPara>

              <StyledPara>
                Charitable Impact works with advisors across financial institutions. When you recommend us to your clients for charitable giving, you can expand your service offering by managing their donated assets. As a result, donors get to work with an advisor they already trust.
              </StyledPara>

              <StyledPara>
                Help your clients reach their giving goals without having to be a charity expert.
              </StyledPara>
            </ContentSection>
          </Col>
          <Col md={10} lg={6}>
            <VideoWrap>
              <iframe
                src="https://www.youtube.com/embed/jbNtWuxqLjk"
                title="Grow your business by helping your clients give."
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
            </VideoWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default VideoSection;
